// for plotting trades on the chart
// make sure you understand HTML5 canvas when editing this file

import toPIPs from '@/mixins/toPIPs'
import useTradePlot from '@/composables/trades/useTradePlot'

import { Overlay } from 'trading-vue-js'

export default {
  name: 'singleTradePlotOverlay',
  setup() {
    const { plotTrade, plotType } = useTradePlot()
    return {
      plotTrade,
      plotType,
    }
  },
  mixins: [Overlay],
  methods: {
    draw(ctx) {
      const context = ctx
      const { layout } = this.$props // Layout object (see trading vue API BOOK)
      const { trade } = this
      // setup
      // colors
      let slColor = 'hsla(0, 100%, 93%, 0.75)'
      let tpColor = 'hsla(137, 100%, 93%, 0.75)'
      let defaultBackground = 'hsla(0, 0%, 100%, 0.6)'
      let defaultStroke = '#D3D3D3'
      let textColor = '#545454'
      if (this.isDark) {
        slColor = '#f56e7434'
        tpColor = '#57c4783a'
        defaultBackground = 'hsla(0, 0%, 100%, 0.08)'
        defaultStroke = '#4a4a4a'
        textColor = '#bfbfbf'
      }
      // historic trades
      // eslint-disable-next-line arrow-body-style
      const localDate = new Date()
      const timeOffset = localDate.getTimezoneOffset() * 60000

      const entryPrice = trade.entryPrice.user || trade.entryPrice
      const entryTime = trade.entryTime.user || trade.entryTime - timeOffset
      const result = trade.result.user || trade.result.signal

      const entryTimeX = layout.t2screen(entryTime) // x - Mapping entry time (x)
      const entryPriceY = layout.$2screen(entryPrice) // Mapping entry price (y)
      const sl = layout.$2screen(trade.sl) // Mapping sl price (y)
      const tp = layout.$2screen(trade.tp) // Mapping tp price (y)
      const rewardPIP = toPIPs(trade.instrument, Math.abs(entryPrice - trade.tp))
      const riskPIP = toPIPs(trade.instrument, Math.abs(entryPrice - trade.sl))

      // stop loss
      context.beginPath()
      context.fillStyle = slColor
      context.strokeStyle = defaultStroke
      context.rect(entryTimeX, entryPriceY, 100, -(entryPriceY - sl)) // Trade sl
      // determine color
      if (result < 0) {
        context.fill()
        context.stroke()
      } else {
        context.strokeStyle = '#f56e7434'
        context.fillStyle = defaultBackground
        context.fill()
        context.stroke()
      }
      // take profit
      context.beginPath()
      context.fillStyle = tpColor
      context.strokeStyle = defaultStroke
      context.rect(entryTimeX, entryPriceY, 100, -(entryPriceY - tp)) // Trade tp
      if (result > 0) {
        context.fill()
        context.stroke()
      } else {
        context.strokeStyle = '#57c4783a'
        context.fillStyle = defaultBackground
        context.fill()
        context.stroke()
      }
      // drawing text
      context.beginPath()
      context.lineWidth = 1
      context.font = '900 10px Nunito'
      context.fillStyle = textColor
      context.textAlign = 'center'
      if (trade.sl < 0) {
        context.fillText(`${rewardPIP} PIPs`, entryTimeX + (100 / 2), entryPriceY + ((tp - entryPriceY) / 2) + 2.5) // trade is buy
        context.fillText(`${riskPIP} PIPs`, entryTimeX + (100 / 2), entryPriceY + ((sl - entryPriceY) / 2) + 2.5)
      } else {
        context.fillText(`${rewardPIP} PIPs`, entryTimeX + (100 / 2), entryPriceY + ((tp - entryPriceY) / 2) + 2.5)
        context.fillText(`${riskPIP} PIPs`, entryTimeX + (100 / 2), entryPriceY + ((sl - entryPriceY) / 2) + 2.5)
      }
      // this seems to run more than it should
    },
    use_for() { return ['singleTradePlotOverlay'] },
  },
  // Define internal setting & constants here
  computed: {
    isDark() {
      return this.$props.settings.isDark
    },
    type() {
      return this.plotType
    },
    trade() {
      return this.plotTrade
    },
  },
}
