<template>
  <div class="trade-plot-chart-wrap">
    <div class="chart-component">
      <div class="chart-top-bar">
        <div class="top-bar-left">
          <button
            class="back-button ml-1 mr-1"
            @click="$router.go(-1)"
          >
            BACK
          </button>
          <div
            v-if="trade"
            class="chart-option"
          >
            <span
              v-if="initialType === 'journal'"
              class="chart-icon"
            >
              you: {{ trade.result.user.toFixed(2) }}% | us: {{ trade.result.signal.toFixed(2) }}%
            </span>
            <span
              v-else
              class="chart-icon"
            >
              result {{ trade.result.signal.toFixed(2) }}%
            </span>
          </div>
        </div>
      </div>
      <div
        ref="chartWrap"
        class="chart-wrapper"
      >
        <resize-observer @notify="handleResize" />
        <Loader v-if="ratesLoading.blocking" />
        <trading-vue
          v-else
          ref="tradingVue"
          :overlays="overlays"
          :data="chartData"
          :color-back="colors.back"
          :color-grid="colors.grid"
          :color-text="colors.text"
          :color-cross="colors.cross"
          :color-candle-up="colors.candle_up"
          :color-candle-dw="colors.candle_dw"
          :color-wick-up="colors.wick_up"
          :color-wick-dw="colors.wick_dw"
          :color-vol-up="colors.volume_up"
          :color-vol-dw="colors.volume_dw"
          :index-based="true"
          :chart-config="config"
          :height="chartHeight"
          :width="chartWidth"
        />
        <div class="cycle-trades">
          <div class="cycle-icons-wrap">
            <i
              class="fas fa-arrow-left cycle-icon"
              @click="incrementPlotIndex(-1)"
            />
            <i
              class="fas fa-arrow-right cycle-icon"
              @click="incrementPlotIndex(1)"
            />
          </div>
          <span class="cycle-number"> {{ plotMetrics.index }} of {{ plotMetrics.length }} </span>
        </div>
      </div>
      <div class="bottom-bar-chart">
        <div>{{ trade.instrument.replace('_', '/') }} trade @ {{ plotMetrics.time }}</div>
      </div>
    </div>
  </div>
</template>

<script>
// import { computed } from '@vue/composition-api'
import useRates from '@/composables/rates/useRates'
import useTradePlot from '@/composables/trades/useTradePlot'
import { ref, watch } from '@vue/composition-api'
import { TradingVue } from 'trading-vue-js'
import Ripple from 'vue-ripple-directive'
import { ResizeObserver } from 'vue-resize'
import 'vue-resize/dist/vue-resize.css'
import Loader from '../../components/Loader.vue'
import singleTradePlotOverlay from '../../components/tvchart/overlays/singleTradePlotOverlay'

export default {
  name: 'PlotSingleTrade',
  directives: { Ripple },
  components: {
    TradingVue,
    ResizeObserver,
    Loader,
  },
  props: {
    initialTrade: {
      type: Object,
      default: () => {},
    },
    initialType: {
      type: String,
      default: '',
    },
    tradeId: {
      type: String,
      default: '',
    },
  },

  setup(props, context) {
    const {
      plotTrade: trade,
      plotMetrics,
      plotRates,
      incrementPlotIndex,
      setPlotTrade,
      currentIndex,
    } = useTradePlot()

    setPlotTrade({ trade: props.initialTrade, plotType: props.initialType })

    const {
      ratesLoading,
      changeRatesTimeframe,
    } = useRates()

    const chartData = ref({
      chart: {
        data: plotRates.value,
        tf: '15m',
        grid: {
          logScale: false,
          id: 0,
        },
      },
      onchart: [
        {
          name: 'Trade',
          type: 'singleTradePlotOverlay',
          data: [],
          settings: {
            'z-index': 10,
          },
        },
      ],
      offchart: [],
    })

    const chartWidth = ref(0)
    const chartHeight = ref(0)

    watch(() => plotRates.value, () => {
      chartWidth.value = document.getElementsByClassName('chart-wrapper')[0].clientWidth
      chartHeight.value = document.getElementsByClassName('chart-wrapper')[0].clientHeight
      chartData.value = {
        chart: {
          data: plotRates.value,
          tf: '15m',
          grid: {
            logScale: false,
            id: 0,
          },
        },
        onchart: [
          {
            name: 'Trade',
            type: 'singleTradePlotOverlay',
            data: [],
            settings: {
              'z-index': 10,
            },
          },
        ],
        offchart: [],
      }
      context.emit('redraw')
    })

    // const data = computed(() => chartData.value)

    return {
      chartData,
      chartHeight,
      chartWidth,
      ratesLoading,
      plotMetrics,
      currentIndex,
      incrementPlotIndex,
      overlays: [singleTradePlotOverlay],
      trade,
      changeRatesTimeframe,
    }
  },
  data() {
    return {
      config: {
        DEFAULT_LEN: 500,
        MINIMUM_LEN: 100,
        CANDLEW: 0.5,
        VOLSCALE: 0.3,
      },
      // eslint-disable-next-line dot-notation
    }
  },
  computed: {
    colors() {
      return this.$isDark.value
        ? {
        // dark theme
          back: '#0e0e0e',
          grid: '#292929',
          text: '#35a776',
          cross: '#fff60',
          candle_up: '#236d3a',
          candle_dw: '#673436',
          wick_up: '#236d3a',
          wick_dw: '#673436',
          volume_up: '#57c4783a',
          volume_dw: '#f56e7434',
        }
        : {
        // light theme
          back: '#fff',
          grid: '#eee',
          text: '#333',
          candle_up: '#57C478',
          candle_dw: '#f56e74',
          wick_up: '#57C478',
          wick_dw: '#f56e74',
          volume_up: '#57c4783a',
          volume_dw: '#f56e7434',
        }
    },
  },
  methods: {
    resetChart() {
      if (this.$refs.tradingVue) {
        this.$refs.tradingVue.resetChart()
      }
    },
    handleResize({ width, height }) {
      this.chartWidth = width
      this.chartHeight = height
    },
  },
}
</script>

<style lang="scss">
.bottom-bar-chart {
  display: flex;
  gap: 1rem;
  height: 32px;
  background-color: lightblue;
  font-weight: 500;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.cycle-trades {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  position: absolute;
  // top: 0;
  bottom: 2rem;
  left: 0;
  right: 0;
  margin: auto;
  width: 200px;
  align-items: center;
  justify-content: center;
  z-index: 100;
  .cycle-icons-wrap {
    display: flex;
    gap: 2rem;
    .cycle-icon {
      cursor: pointer;
      background-color: white;
      padding: 1.4rem 1.5rem;
      border-radius: 100rem;
      border: 1px solid hsl(0, 0%, 11%);
      font-size: 26px !important;
    }
  }
  .cycle-number {
    font-size: 1.2rem;
    font-weight: 600;
  }
}

.trading-graph {
  cursor: crosshair;
}

.datasets {
  display: flex;
  align-items: center;
  .dataset-dot {
    margin-left: 1rem;
    margin-right: 0.4rem;
    padding: 6px;
    border-radius: 100rem;
    &.creation {
      background-color: rgba(39, 63, 245, 0.08);
    }
    &.training {
      background-color: rgba(245, 39, 243, 0.08);
    }
  }
}

.single-active-pair {
  margin: 0;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  font-size: 13px;
  font-weight: 600;
}
.back-button {
  border: none;
  padding: 2px 8px 2px 8px;
  margin-left: 4px;
  font-weight: 500;
  font-size: 12px;
  color: #f0f0f0;
  border-radius: 20rem;
  background-color: #28c870;
}

.resize-observer {
  width: 100%;
  height: 100%;
}
.chart-component {
  width: 100%;
  height: 100%;
  min-height: 280px;
  display: flex;
  flex-direction: column;
  .chart-wrapper {
    width: 100%;
    height: calc(100% - 64px);
    display: flex;
    position: relative;
  }
}
.pairings-dropdown-content {
  position: relative;
  z-index: 3333;
}
.trading-vue-chart {
  position: relative;
  height: 100%;
  .trading-vue-legend {
    max-width: 45% !important;
    z-index: 1;
    .t-vue-title {
      display: none;
    }
  }
  .t-vue-ind {
    display: none;
  }
  .trading-vue-botbar {
    #trading-vue-js-botbar-canvas {
      height: 100%;
    }
  }
}

.dark-layout {
  .chart-top-bar {
    background: #0e0e0e;
    border-bottom: 1px solid hsl(0, 0%, 21%);
    .chart-option {
      .chart-icon {
        border-color: hsl(0, 0%, 21%);
        &.clicked {
          color: rgb(46, 139, 85);
        }
      }
    }
    .pairings-dropdown {
      border-right: 1px solid hsl(0, 0%, 21%);
      ::after {
        background-color: hsl(0, 0%, 21%) !important;
      }
      .btn-secondary {
        color: rgb(219, 219, 219) !important;
      }
    }
  }
}

//
.trade-plot-chart-wrap {
  position: relative;
  padding-top: 4.55rem;
  align-items: stretch;
  height: 100vh;
  width: 100%;
}

</style>
